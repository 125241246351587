<template>
  <div>
    <calendarBreadcrumbs :link="link" :linkAll="'bookingCalendarDate'" :params="getDate()" />
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs() {
      return import('@/components/assets/calendarBreadcrumbs')
    }
  },
  props: {},
  data() {
    return {
      link: 'specificBookingCalendar',
      variables: this.$getUserVariables()
    }
  },
  methods: {
    getDate() {
      var date = this.$store.getters.calendarDate
      if (!date) {
        date = new Date()
      }
      return {
        date:
          date.getFullYear() +
          '-' +
          this.$getNullsBefore(date.getMonth() + 1) +
          '-' +
          this.$getNullsBefore(date.getDate())
      }
    }
  },
  mounted() {
    if (this.variables.state === 'share') {
      this.link = 'sharesSpecificBookingCalendar'
    }
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
